import styled from "styled-components";

export const MainDiv = styled.div`
  font-family: montserrat;
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Card = styled.div`
  position: absolute;
  z-index: 1;
  right: 160px;
  top: 375px;
  padding: 50px;
  box-shadow: 0px 0px 20px 5px #fdfdfd7a;
  width: 440px;
  border-radius: 25px;
  background-color: white;

  @media (max-width: 1440px) {
    padding: 30px;
    right: 105px;
    top: 250px;
    width: 275px;
  }

  @media (max-width: 1024px) {
    right: 70px;
    top: 200px;
    width: 250px;
  }

  @media (max-width: 768px) {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    top: 280px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 2px #6e6e6ed1;
  }
  @media (max-width: 600px) {
    top: 200px;
  }

  @media (max-width: 540px) {
    top: 160px;
  }
  @media (max-width: 425px) {
    padding: 18px;
    top: 150px;
    width: 80%;
  }

  @media (max-width: 375px) {
    top: 100px;
  }
`;

export const Header1 = styled.h1`
  font-weight: bold;
  text-align: center;
  font-size: 52px;
  padding-bottom: 12px;
  color:var(--font-color);
  @media (max-width: 1440px) {
    font-size: 30px;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
  }
  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

export const Header3 = styled.h3`
  font-size: 30px;
  text-align: center;
  color:var(--font-color);
  @media (max-width: 1440px) {
    font-size: 18px;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 13px;
  }
`;
